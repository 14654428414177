import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import DeviceSettingsForm from "./DeviceSettings/DeviceSettingsForm";
import AreaSettingsForm from "./AreaSettings/AreaSettingsForm";
import PrinterSettingsForm from "./Printer/PrinterSettings";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  entitySettingsDelete,
  entitySettingsUpdate,
} from "../../../state/slices/entities";

import {
  updateEntitySettings,
  deleteEntitySettings,
  setSettings as coreSetSettings
} from "../../../state/slices/CoreEntity";

import { Drawer } from "@material-ui/core";
import TotalOccupancy from "../../Occupancy/CreateOccupancy/TotalOccupancy";
import { useStyles } from "./styles";
import ApplyTaxesContainer from "../../Taxes/Apply/ApplyTaxesEditor";
import RateSelector from "../../RateSelector";
import CCGatewayProvider from "../../../providers/CreditCardGatewayProvider";
import GatewayConfiguration from "../../Gateway/GatewayConfiguration";
import TicketSettingsForm from "./TicketSettings";
import ShortChangeTransactionsForm from "./ShortChangeTransactions";
import EmailSettingsForm from "./EmailSettings";
import ThirdPartyPassSettingsForm from "./ThirdPartyPass/ThirdPartyPassSettings";
import useHasPermissions from "../../../hooks/useHasPermissions";
import { useFeatureFlag } from "../../../hooks/useFeatureFlags"
import useEntitySettings from "../../../hooks/useEntitySettings";
import useCurrentFacility from "../../../hooks/useCurrentFacility";
import PeripheralsEditor from "../../PeripheralsEditor";
import MobilePaySettingsForm from "./MobilePaySettings";
import FacilityValidationsSettingsForm from "./FacilityValidations"
import ValetAreaTicketSettingsForm from "./ValetAreaTicketSettings"
import TicketFieldsSettingsForm from "./TicketFieldsSettings"
import CardFormats from "../../CardFormats/Cardformats";
import GPIOExpandedView from "../../GPIOConfiguration/GPIOExpandedView";
import IntercomProviderSettingsForm from "./IntercomProvider";
import LPRSettingsForm from "./LPR";
import CVPSSettings from "./CVPS";
import CardSystemCodes from "../../CardSystemCodes";
import CancelIcon from "@material-ui/icons/Cancel";
import { Button, Grid } from "@material-ui/core";
import clsx from "clsx";
import useFormHandlers from "./useFormHandlers";
import TaxForm from "../Taxes";
import ReverseLane from "../ReverseLane";
import PayOnEntrySettings from "./PayOnEntrySettings";
import { DEVICE_MODE, PERIPHERAL_TYPES, TICKET_FIELD_REQUEST_TYPE } from "../../../constants";
import * as _ from "lodash";
import { setSettings } from "../../../state/slices/entities";
import HotelRatesExpandedView from "../../HotelRatesConfiguration/HotelRatesExpandedView";
import TicketmasterSettingsForm from "./Ticketmaster";
import FacilityOfflineFunctionalitySettingsForm from "./FacilityOfflineFunctionalitySettingsForm";
import GuestTypesEditor from "../../GuestTypeEditor";
import TicketFields from "../../TicketFields/TicketFields";
import LogosAndBanners from "../../LogosAndBanners";
import ScheduleReportConfigurationForm from "./ScheduleReportConfiguration"
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {useCoreEntityContext} from "../../../hooks/useCoreEntitySlice";
import {useFlags} from "launchdarkly-react-client-sdk";

const SettingsForm = ({
  entityID,
  entityType,
  settingValues,
  settingValuesParent,
  parentEntityID
}) => {

  const classes = useStyles();

  const { virtualValidationLimit, valetArea } = useFlags();

  const [drawerState, setDrawerState] = useState({
    open: false,
    contentType: "",
  });
  const [refreshValetTicketLengthSettingsForm, setRefreshValetTicketLengthSettingsForm] = useState(false);
  const { canRenderField, canRenderFieldByMode } = useFormHandlers(
    undefined,
    undefined,
    undefined,
    entityType
  );
  const { hasPermissions } = useHasPermissions();
  const EditFacilitySettingsPermission = hasPermissions(
    ["overview.facilities.editsettings"],
    false,
    entityID
  );

  const peripherals = useSelector((state) => {
    return state?.peripherals?.peripherals;
  });

  const deviceMode = settingValues?.devicemode?.toLowerCase();
  const isOfflineFeatureEnabled = useFeatureFlag('Offline');
  const isOfflinePhaseOneEnabled = useFeatureFlag('Offline.Phase 1');

  const isAdmin = useSelector((state) => state?.user?.isAdmin);
  const isLPREnabled = useFeatureFlag('LPR');
  const isCVPSEnabled = useFeatureFlag("CVPS Dual Pairing");
  const isTicketmasterEnabled = useFeatureFlag('Ticketmaster');
  const payOnEntry = useFeatureFlag('Pay On Entry');
  const ScheduledReportsFlag = useFeatureFlag('Scheduled Reports');
  const { shortChangeTransactions } = useFlags();
  const lprTicketless = settingValues?.lprTicketless ?? false;
  const poeEnabled = settingValues?.poeEnabled ?? false;
  const closeDrawer = () => {
    //As guesttypes might have got modified when GuestType drawer closes,
    //better to rerender valet ticket length setting form to make ticket length validation work
    //as per latest guest types availability
    if (drawerState.contentType === "guesttype") {
      setRefreshValetTicketLengthSettingsForm(prev => !prev);
    }
    setDrawerState({ open: false, contentType: "" });
  };

  const getDrawerContent = useCallback(
    (contentType) => {
      switch (contentType?.toLowerCase()) {
        case "occupancy":
          return <TotalOccupancy entityID={entityID} onClose={closeDrawer} />;
        case "taxes":
          return (
            <ApplyTaxesContainer entityid={entityID} onClose={closeDrawer} />
          );
        case "rates":
          return <RateSelector entityID={entityID} onClose={closeDrawer} />;
        case "peripherals":
          return (
            <CCGatewayProvider><PeripheralsEditor entityID={entityID} onClose={closeDrawer} /></CCGatewayProvider>
          );
        case "gpio":
          return <GPIOExpandedView entityID={entityID} onClose={closeDrawer} />;
        case "hotelrates":
          return <HotelRatesExpandedView entityID={entityID} hotelInterfaceSettings={settingValues?.hotelinterface} onClose={closeDrawer} />;
        case "creditcard":
          return (
            <CCGatewayProvider>
              <GatewayConfiguration
                entityType={entityType}
                onClose={closeDrawer}
                facilityID={entityID}
              />
            </CCGatewayProvider>
          );
        case "cardformats":
          return (
            <div
              className={clsx("card-formats-drawer", classes.cardFormatDrawer)}
            >
              <CardFormats entityID={entityID} />
              <CardSystemCodes entityID={entityID} />
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                className={clsx("cancel-button-container", classes.cancelGrid)}
              >
                <Button
                  startIcon={<CancelIcon />}
                  className={clsx(["cancel", "button"], classes.cancelButton)}
                  name="cancel"
                  variant="contained"
                  onClick={closeDrawer}
                >
                  Cancel
                </Button>
              </Grid>
            </div>
          );
        case "taxsettings":
          return <TaxForm entityID={entityID} onClose={closeDrawer} />;
        case "reversinglane":
          return <ReverseLane entityID={entityID} onClose={closeDrawer} />;
        case "guesttype":
          return (
            <GuestTypesEditor entityID={entityID} onClose={closeDrawer} />
          );
        case "arrivalticketfields":
          return (
            <TicketFields entityID={entityID} requestType={TICKET_FIELD_REQUEST_TYPE.ARRIVAL} onClose={closeDrawer} />
          );
        case "requestticketfields":
          return (
            <TicketFields entityID={entityID} requestType={TICKET_FIELD_REQUEST_TYPE.REQUEST} onClose={closeDrawer} />
          );
        case "logosandbanners":
          return (
            <LogosAndBanners entityID={entityID} onClose={closeDrawer} parentEntityID={parentEntityID} entityType={entityType} />
          )
      }
    },
    [drawerState.contentType, peripherals]
  );

  const handleTaxesClicked = () => {
    setDrawerState({ open: true, contentType: "taxes" });
  };

  const handleRatesClicked = () => {
    setDrawerState({ open: true, contentType: "rates" });
  };

  const handlePeripheralsClicked = () => {
    setDrawerState({ open: true, contentType: "peripherals" });
  };

  const handleGPIOClicked = () => {
    setDrawerState({ open: true, contentType: "gpio" });
  };

  const handleHotelRatesClicked = () => {
    setDrawerState({ open: true, contentType: "hotelrates" });
  };

  const handleOccupancyClicked = () => {
    setDrawerState({ open: true, contentType: "occupancy" });
  };

  const handleCCGatewayClicked = () => {
    setDrawerState({ open: true, contentType: "creditcard" });
  };

  const onCardFormatsClicked = () => {
    setDrawerState({ open: true, contentType: "cardformats" });
  };

  const onTaxSettingsClicked = () => {
    setDrawerState({ open: true, contentType: "taxsettings" });
  };

  const onReversingLanesClicked = () => {
    setDrawerState({ open: true, contentType: "reversinglane" });
  };

  const handleGuestTypesClicked = () => {
    setDrawerState({ open: true, contentType: "guesttype" });
  };

  const handleArrivalTicketFieldsClicked = () => {
    setDrawerState({ open: true, contentType: "arrivalticketfields" });
  };

  const handleRequestTicketFieldsClicked = () => {
    setDrawerState({ open: true, contentType: "requestticketfields" });
  };

  const handleLogosAndBannersClicked = () => {
    setDrawerState({ open: true, contentType: "logosandbanners" })
  };

  const canRenderPOE = () => {
    //For Facility Level
    if (entityType?.toLowerCase() === "facility")
      return payOnEntry && canRenderField("payOnEntry");
    //For Device Level
    if (_.isEmpty(peripherals)) return false;
    const printer = peripherals.find(
      (x) => x.peripheralTypeID === PERIPHERAL_TYPES.PRINTER.id && x.isActive
    );
    const creditCard = peripherals.find(
      (x) =>
        x.peripheralTypeID === PERIPHERAL_TYPES.CREDITCARDREADER.id &&
        x.isActive
    );
    const hasPermission = canRenderFieldByMode("payOnEntry", deviceMode);
    return (
      payOnEntry && hasPermission && printer?.isActive && creditCard?.isActive && 
      (deviceMode === DEVICE_MODE.ENTRY.toLowerCase() ? !lprTicketless || poeEnabled : hasPermission) 
    );
  };

  const canRenderLPR = () => {
    return isLPREnabled ? canRenderFieldByMode("lpr", deviceMode) : false;
  };
  const canRenderShortChangeTransactions = () => {
    return shortChangeTransactions ? canRenderFieldByMode("shortchangeenabled", deviceMode) : false;
  }
  const canRenderReceiptForm = () => {    
    return canRenderFieldByMode("receiptForm", deviceMode) && 
    deviceMode === DEVICE_MODE.ENTRY.toLowerCase() ? !lprTicketless || poeEnabled : true;
  };
  const canRenderTicketForm = () => {
    return canRenderFieldByMode("ticketForm", deviceMode) && 
    deviceMode === DEVICE_MODE.ENTRY.toLowerCase() ? !lprTicketless || poeEnabled : true;
  };
  const canRenderTicketMasterForm = () => {
    return isTicketmasterEnabled && (entityType?.toLowerCase() === "facility" ||
      (deviceMode?.toLowerCase() === DEVICE_MODE.ENTRY.toLowerCase() && !lprTicketless) ||
      (deviceMode?.toLowerCase() === DEVICE_MODE.ENTRY.toLowerCase() && poeEnabled))
  };
  
  return (
    <>
      {canRenderPOE() && (
        <PayOnEntrySettings
          identifier="poe-settings-panel"
          entityID={entityID}
          entityType={entityType}
          settingValues={settingValues}
          parentSettingValues={settingValuesParent}
        />
      )}

      {entityType?.toLowerCase() != "area" && (
        <DeviceSettingsForm
          entityID={entityID}
          entityType={entityType}
          settingValues={settingValues}
          settingValuesParent={settingValuesParent}
          onTaxesClicked={handleTaxesClicked}
          onRatesClicked={handleRatesClicked}
          onPeripheralsClicked={handlePeripheralsClicked}
          onGPIOClicked={handleGPIOClicked}
          onOccupancyClicked={handleOccupancyClicked}
          onCCGatewayClicked={handleCCGatewayClicked}
          onCardFormatsClicked={onCardFormatsClicked}
          onTaxSettingsClicked={onTaxSettingsClicked}
          onReversingLanesClicked={onReversingLanesClicked}
          onHotelRatesClicked={handleHotelRatesClicked}
          onLogosAndBannersClicked={handleLogosAndBannersClicked}
        />
      )}

      {entityType?.toLowerCase() == "area" && (
        <AreaSettingsForm
          entityID={entityID}
          entityType={entityType}
          settingValues={settingValues}
        />
      )}

      {isOfflineFeatureEnabled && isOfflinePhaseOneEnabled && entityType?.toLowerCase() === "facility" && (
        <FacilityOfflineFunctionalitySettingsForm
          entityID={entityID}
          entityType={entityType}
          settingValues={settingValues}
        />
      )}

      {virtualValidationLimit && canRenderField("facilityvalidations") && (
        <FacilityValidationsSettingsForm
          entityID={entityID}
          entityType={entityType}
          settingValues={settingValues}
          disabled={!EditFacilitySettingsPermission}
        />
      )}

      {canRenderFieldByMode("intercomprovider", deviceMode) && (
        <IntercomProviderSettingsForm
          entityID={entityID}
          entityType={entityType}
          settingValues={settingValues}
          settingValuesParent={settingValuesParent}
        />
      )}

      {canRenderField("mobilepayform") && (
        <MobilePaySettingsForm
          entityID={entityID}
          disabled={!EditFacilitySettingsPermission}
        />
      )}

      {canRenderReceiptForm() && (
        <PrinterSettingsForm
          entityID={entityID}
          entityType={entityType}
          settingValues={settingValues}
          settingValuesParent={settingValuesParent}                    
        />
      )}

      {canRenderTicketForm() && (
        <TicketSettingsForm
          entityID={entityID}
          entityType={entityType}
          settingValues={settingValues}
          settingValuesParent={settingValuesParent}          
        />
      )}

      {canRenderLPR() && (
        <LPRSettingsForm
          identifier="lpr-settings-panel"
          entityID={entityID}
          entityType={entityType}
          settingValues={settingValues}
          settingValuesParent={settingValuesParent}
          isAdmin={isAdmin}
        />
      )}

      {isCVPSEnabled && entityType === "Facility" && <CVPSSettings />}

      {canRenderShortChangeTransactions() && (
        <ShortChangeTransactionsForm
          identifier="short-change-panel"
          entityID={entityID}
          entityType={entityType}
          settingValues={settingValues}
          disabled={!EditFacilitySettingsPermission}

        />
      )}

      {ScheduledReportsFlag && canRenderField("ScheduleReportConfigurationForm") && (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <ScheduleReportConfigurationForm
            identifier="Schedule-Report-Configuration"
            entityID={entityID}
            entityType={entityType}
            settingValues={settingValues}
            disabled={!EditFacilitySettingsPermission}
          />
        </MuiPickersUtilsProvider>
      )}

      {canRenderField("thirdpartyform") && (
        <ThirdPartyPassSettingsForm
          entityID={entityID}
          entityType={entityType}
          settingValues={settingValues}
          disabled={!EditFacilitySettingsPermission}
        />
      )}

      {canRenderField("emailform") && (
        <EmailSettingsForm
          entityID={entityID}
          entityType={entityType}
          settingValues={settingValues}
          settingValuesParent={settingValuesParent}
          disabled={!EditFacilitySettingsPermission}
        />
      )}

      {canRenderTicketMasterForm() && (
        <TicketmasterSettingsForm
          identifier="ticketmaster-panel"
          entityID={entityID}
          settingValues={settingValues}          
        />
      )}

      {valetArea && canRenderField("valetareaticketsettings") && (
        <ValetAreaTicketSettingsForm
          entityID={entityID}
          settingValues={settingValues}
          onGuestTypesClicked={handleGuestTypesClicked}
          //Rerenders the component whenever key value changes
          key={refreshValetTicketLengthSettingsForm}
        />
      )}

      {valetArea && canRenderField("arrivalsettings") && (
        <TicketFieldsSettingsForm
          requestType={TICKET_FIELD_REQUEST_TYPE.ARRIVAL}
          onTicketFieldsButtonClicked={handleArrivalTicketFieldsClicked}
        />
      )}

      {valetArea && canRenderField("Requestsettings") && (
        <TicketFieldsSettingsForm
          requestType={TICKET_FIELD_REQUEST_TYPE.REQUEST}
          onTicketFieldsButtonClicked={handleRequestTicketFieldsClicked}
        />
      )}

      <Drawer
        className={clsx("drawer", drawerState.contentType, classes.drawer)}
        anchor="right"
        open={drawerState.open}
        classes={{
          paper: drawerState.contentType === "logosandbanners" ? classes.logoDrawerPaper: classes.drawerPaper,
        }}
        onClose={closeDrawer}>
        {getDrawerContent(drawerState.contentType)}
      </Drawer>
    </>
  );
};

SettingsForm.propTypes = {
  entityID: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
};

export default SettingsForm;

export const useSettingDispatchChange = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user);
  const { broadcastBulkSettingChange } = useEntitySettings();
  const { facilityID } = useCurrentFacility();
  const useCoreEntitySlice = useCoreEntityContext();
  const dispatchSetting = async (entityID, eventName, eventValue) => {
    let payload = {
      userName: currentUser.EmailAddress,
      entityId: entityID,
      settingName: "setting." + eventName,
      settingValue: eventValue,
    };
    try {

      let _newSettings = unwrapResult(
          await dispatch(useCoreEntitySlice ? updateEntitySettings([payload]) : entitySettingsUpdate([payload]))
      ).flat();

      await dispatch(useCoreEntitySlice ? coreSetSettings(_newSettings) : setSettings(_newSettings));

      broadcastBulkSettingChange({ payload: _newSettings, target: facilityID });

      return true;
      //they have already been written to database and redux updated, just also available here.
    } catch (err) {
      console.error(err);
      //was unable to write to db or update redux
      return false;
    }
  };

  const dispatchDeleteSetting = async (entityID, eventName, settingValue) => {
    let payload = {
      userName: currentUser.EmailAddress,
      entityId: entityID,
      settingName: "setting." + eventName,
      settingValue: settingValue,
    };
    try {
      let _newSettings = unwrapResult(
        await dispatch(useCoreEntitySlice ? deleteEntitySettings(payload) : entitySettingsDelete(payload))
      );
      return true;
      //they have already been written to database and redux updated, just also available here.
    } catch (err) {
      console.error(err);
      //was unable to write to db or update redux
      return false;
    } finally {
      //do a thing here to remove disable
    }
  };

  const dispatchBulkSettings = async (targetNameList, values, entityID) => {
    const payloadArray = [];

    for (const targetName of targetNameList) {
      const settingValue = values[targetName];

      if (settingValue !== undefined) {
        const payload = {
          userName: currentUser.EmailAddress,
          entityId: entityID,
          settingName: `setting.${targetName}`,
          settingValue: settingValue,
        };

        payloadArray.push(payload);
      }
    }
    try {
      let _newSettings = unwrapResult(
        await dispatch(useCoreEntitySlice ? updateEntitySettings([payloadArray]) : entitySettingsUpdate(payloadArray))
      ).flat();

      await dispatch(useCoreEntitySlice ? coreSetSettings(_newSettings) : setSettings(_newSettings));

      broadcastBulkSettingChange({ payload: _newSettings });

      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  return { dispatchSetting, dispatchDeleteSetting, dispatchBulkSettings };
};
